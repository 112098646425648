<template>
    <v-container class="pa-0" fluid>
        <v-menu :close-on-content-click="false" :disabled="!showResponseSetsMenu" nudge-right="220" open-on-hover>

            <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot:default="{ hover }">
                    <v-container v-bind="attrs" v-on="on"
                                 :class="getResponseClass(hover)"
                                 class="text-start"
                                 @click="responseClicked">
                        <v-btn :color="response.iconColor" class="me-3" icon outlined small>
                            <v-icon :color="response.iconColor" small>{{ response.icon }}</v-icon>
                        </v-btn>
                        {{ response.title }}
                    </v-container>
                </v-hover>
            </template>

            <v-card>
                <v-card-title>Response Sets</v-card-title>
                <v-card-text v-if="getResponseSet" class="text-start">
                    <v-row v-for="(set, index) in getResponseSet" :key="index" dense>
                        <v-col v-if="isDropDown || (isMultiChoice && set.title)">
                            <v-btn color="primary" small @click="responseSetClicked(index)">
                                {{ set.title }}
                            </v-btn>
                        </v-col>
                        <v-col v-else @click="responseSetClicked(index)">
                            <v-btn v-for="(item, index) in set.items" :key="index" :color="item.color.hex" class="ms-1"
                                   x-small>
                                {{ item.label }}
                            </v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn icon small @click="editResponseSet(index)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn icon small @click="removeResponseSet(index)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="primary" outlined @click.stop="showDialog = true">Create Set</v-btn>
                </v-card-actions>
            </v-card>

        </v-menu>

        <kurcc-response-set-creator :for-edit-response-set.sync="forEditResponseSet" :show-dialog.sync="showDialog"
                                    :type="response.type"/>
    </v-container>
</template>

<script>
import {ResponseType} from '@/modules/forms/enums/response-type';
import {
    DELETE_INSPECTION_FORM_RESPONSE_SET,
    SET_SNACKBAR_COLOR, SET_SNACKBAR_DISPLAY_STATE,
    SET_SNACKBAR_MESSAGE
} from "@/modules/app/store/mutation-types";
import {QuestionType} from "@/modules/forms/enums/question-type";

export default {
    name: "KurccQuestionResponse",
    components: {
        KurccResponseSetCreator: () => import('@/modules/forms/components/KurccResponseSetCreator')
    },
    props: {
        response: {
            type: Object,
            required: true
        },
        disableHoverEffect: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showDialog: false,
            forEditResponseSet: undefined
        }
    },
    computed: {
        getResponseClass() {
            return hover => ({
                'response-hover-pointer': hover,
                'grey': hover && !this.disableHoverEffect,
                'lighten-5': hover && !this.disableHoverEffect
            })
        },
        localResponse: {
            get() {
                return this.response
            },
            set(v) {
                this.$emit('update:response', v)
            }
        },
        isDropDown() {
            return this.localResponse.type === ResponseType.Dropdown
        },
        isMultiChoice() {
            return this.localResponse.type === ResponseType["Multiple choice"]
        },
        showResponseSetsMenu() {
            return (this.isDropDown || this.isMultiChoice) && !this.disableHoverEffect
        },
        getResponseSet() {
            if (this.isDropDown)
                return this.dropdownResponseSet
            if (this.isMultiChoice)
                return this.multipleChoiceResponseSet
            else return null
        },
        dropdownResponseSet() {
            return this.form.responseSets.dropdown
        },
        multipleChoiceResponseSet() {
            return this.form.responseSets.multiple
        },
        form() {
            return this.$store.state.forms.form
        }
    },
    methods: {
        removeResponseSet(index) {
            if (!this.isResponseSetInUse(index)) {
                this.$store.commit(DELETE_INSPECTION_FORM_RESPONSE_SET, {index, type: this.response.type})
                this.setResponse()
            } else {
                this.$store.commit(SET_SNACKBAR_COLOR, 'red')
                this.$store.commit(SET_SNACKBAR_MESSAGE, 'This response set is in use, it can not be deleted!')
                this.$store.commit(SET_SNACKBAR_DISPLAY_STATE, true)
            }
        },
        editResponseSet(index) {
            this.forEditResponseSet = this.getResponseSet[index]
            this.showDialog = true
        },
        responseSetClicked(index) {
            this.setResponseSet(index)
            this.setResponse()
        },
        responseClicked() {
            if (!this.showResponseSetsMenu)
                this.setResponse()
        },
        setResponse() {
            this.$emit('click')
        },
        setResponseSet(value) {
            if (typeof value === "object")
                this.localResponse.responseSet = value
            else
                this.localResponse.responseSet = this.getResponseSet[value]
        },
        isResponseSetInUse(index) {
            let isInUse = false
            this.form.pages.forEach(page => {
                page.questions.forEach(question => {
                    if (question.type === QuestionType.check)
                        if (question.options.response.type === ResponseType.Dropdown || question.options.response.type === ResponseType["Multiple choice"]) {
                            if (this.$lodash.isEqual(this.getResponseSet[index], question.options.response.responseSet)) {
                                isInUse = true
                            }
                        }
                })
            })
            return isInUse
        }
    }
}
</script>

<style scoped>
.response-hover-pointer {
    cursor: pointer;
}
</style>
